

//create CRUD class with axios
import axios, { AxiosResponse } from 'axios';


export default class DataService {
    //prod / dev
    // private apiUrl: string = "https://b8f74o2sda.execute-api.ap-southeast-1.amazonaws.com";
    
    //beta
    private apiUrl: string = "https://ixkk2e07of.execute-api.ap-southeast-1.amazonaws.com";
   
    private path: string;
    private storeId: string = '';

    //create instance axios with json header
    private axios = axios.create({
        headers: {
            'Content-Type': 'application/json'
        }
    });

    constructor(path: string) {

        //check if start with slash
        if (path[0] !== '/') {
            path = '/' + path;
        }

        this.path = path;  // path begin with /(slash)
        this.setStoreId("demo01")
    }

    //set customer id
    setStoreId(id: string) {
        this.storeId = id;
        //set auth header
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${this.storeId}`;

    }

    // GET all records
    async getAll(): Promise<AxiosResponse> {
        return await this.axios.get(`${this.apiUrl}${this.path}`);
    }

    // GET a single record by ID
    async getById(id: string): Promise<AxiosResponse> {
        return await this.axios.get(`${this.apiUrl}${this.path}/${id}`);
    }

    // POST (create) a new record
    async post(data: any): Promise<AxiosResponse> {

        //set storeid
        if( !data.storeId) { 
            data.storeId = this.storeId;
            data.createdAt = new Date().toISOString();
        }

        return await axios.post(`${this.apiUrl}${this.path}`, data);
    }

    // PUT (update) an existing record
    async put(data: any): Promise<AxiosResponse> {

        //set storeid
        if( !data.storeId) { 
            data.storeId = this.storeId;
            data.updatedAt = new Date().toISOString();
        }

        return await axios.put(`${this.apiUrl}${this.path}`, data);
    }

    // DELETE a record by ID
    async delete(data: any): Promise<AxiosResponse> {
        //set storeid
        if( !data.storeId) { 
            data.storeId = this.storeId;
        }

        return await axios.delete(`${this.apiUrl}${this.path}`, { data: data });
    }
}


// const dataService = new DataService('https://your-api-url.com','/users');
// const response = await dataService.getAll();



import dayjs from 'dayjs';
import { getDatabase, ref as refDb, onValue, get } from "firebase/database";


export const useServerTime = createGlobalState(function () {

    const serverTimeOffset = ref(null);  

    const getCorrectedDayjsInstance = () => {
        if (!serverTimeOffset.value) {
            throw new Error("Server time offset not loaded yet");
        }

        const localTime = dayjs();
        const correctedLocalTime = localTime.add(serverTimeOffset.value, 'millisecond');
        return correctedLocalTime;
    };

    if (serverTimeOffset.value == null) {
        const db = getDatabase();
        const offsetRef = refDb(db, ".info/serverTimeOffset");
        let current = dayjs();
        onValue(offsetRef, (snap) => {
            const offset = snap.val();
            serverTimeOffset.value = offset;


            //if diff more than 1 min, write html to page that time is not correct
            const newTime = dayjs().add(offset, 'millisecond');
            const diff = newTime.diff(current, 'minute');
            if (Math.abs(diff) > 10) {
                document.body.innerHTML = `<div style="position:fixed; top:0; left:0; width:100%; height:100%; background-color:white; z-index:99999999; font-size: 2rem; text-align:center; padding-top: 10rem;">Your computer time is not correct. Please fix it and reload the page.</div>`
            }

        });
    }

    return { getCorrectedDayjsInstance };
});
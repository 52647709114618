import { useStorage, useLocalStorage } from "@vueuse/core";
import { getAuth, sendPasswordResetEmail, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import fbApp from "../../../firebaseInstance";
import { nanoid } from "nanoid";
import dataservice from "../services/dataservice";
import { getCookie, removeCookie } from "~/modules/core/utils/cookie";

// auth ,  restore, fetch, watch , ready
export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      uid: "rice",
      computerUuid: "" as any,
    };
  },

  actions: {
    init() {
      this.computerUuid = useLocalStorage("computerUuid", nanoid(6));
      const auth = getAuth();      
      
      const cookieData = getCookie("xtable") || null
      if(cookieData){
        const parsedData = JSON.parse(cookieData)
        this.uid = parsedData.uid   
        useRouter().push("/")
        return
      }


      //return promise whein user is ready
      return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            this.uid = user.uid;
            resolve(user);
          } else {
            // User is signed out
            // window.location.href = '/login'
            useRouter().push("/login");
          }
        });
      });
    },
    async createUserAndStore({ email, password, storeId }, callback) {
      try {
        const auth = getAuth();
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        await _createStore(user, storeId);
        callback && callback(user);
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
      }
    },

    login(email, password, callback) {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          // ...

          callback && callback(user);
        })
        .catch((error, abc) => {
          const errorCode = error.code;
          const errorMessage = error.message;

          alert(errorMessage);
        });
    },
    logout() {
      removeCookie("xtable", ".xtable.co")
      const auth = getAuth();
      auth
        .signOut()
        .then(() => {
          // Sign-out successful.
          
          window.location.href = "/login";
        })
        .catch((error) => {
          // An error happened.

          alert(error);
        });
    },
    async resetPassword(email:string){
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email)
    }, 

    createUser() {},

    run() {},
  },
});

function _createStore({ uid, email }, storeId = null) {
  return dataservice.store.post({
    name: "My New Store",
    uid: uid,
    email: email,
    storeId: storeId || null,
  });
}

export enum SETTING_ID {
  SHOP_NAME = 'name',
  SHOP_LOGO='shop_logo',
  RECEIPT_LOGO='receipt_logo',
  SERVICE_CHARGE = 'service_charge',
  ROUNDING = 'rounding',

  ROUNDING_OPTION_NONE = 'none',
  ROUNDING_OPTION_NEAREST = 'nearest',
  ROUNDING_OPTION_UP = 'up',
  ROUNDING_OPTION_DOWN = 'down',

  TAX_MODE = 'tax_mode',
  TAX_VALUE = 'tax_value',

  DISCOUNT_BILL = 'discount_bill',
  PAYMENT = 'payment',
  ROOMS = 'rooms',
  QR_PROMTPAY = 'qr_promtpay',

  TABLES = 'tables',

  REPORT_SUMMARY = 'report_summary',
  REPORT_ITEMS = 'report_items',
  REPORT_VOIDED = 'report_voided',
  REPORT_OPENMENU = 'report_openmenu',

  PRINTER_RECEIPT = 'printer_receipt',
  PRINTER_RECEIPT_COPY = 'printer_receipt_copy',
  PRINTER_RECEIPT_COMMENTS = 'printer_receipt_comments',
  PRINTER_JOB_MOVE_TABLE = 'printer_job_move_table',
  PRINTER_JOB_VOID_ITEMS =   'printer_job_void_items',
  PRINTER_JOB_SHOW_PRICE = 'printer_job_show_price',
  PRINTER_JOB_SKIP_PRICE_ZERO = 'printer_job_skip_price_zero',
  PRINTER_SHOW_TIMEIN_TIMEOUT = 'printer_show_timein_timeout',

  PRINTER_PREVIEW_HEADER = 'printer_preview_header',
  PRINTER_PREVIEW_FOOTER = 'printer_preview_footer',

  PRINTER_RECEIPT_HEADER = 'printer_receipt_header',
  PRINTER_RECEIPT_FOOTER = 'printer_receipt_footer',

  PRINTER_0_MODE = 'printer_0_mode',
  PRINTER_1_MODE = 'printer_1_mode',
  PRINTER_2_MODE = 'printer_2_mode',
  PRINTER_3_MODE = 'printer_3_mode',
  PRINTER_4_MODE = 'printer_4_mode',
  PRINTER_5_MODE = 'printer_5_mode',

  PRINTER_RECEIPT_FONT_SIZE = 'printer_receipt_font_size',
  PRINTER_JOB_KITCHEN_FONT_SIZE = 'printer_job_kitchen_font_size',


  QRCODE_MENU_1 =  'qrcode_menu_1',
  QRCODE_MENU_2 =  'qrcode_menu_2',
  QRCODE_MENU_3 =  'qrcode_menu_3',
  QRCODE_MENU_4 =  'qrcode_menu_4',
  QRCODE_MENU_5 =  'qrcode_menu_5',

  OPEN_MENU_MODE = 'open_menu_mode',
  OPEN_MENU_TYPE = 'open_menu_type',
  OPEN_MENU_ID = 'open_menu_group',

  
  
  EMAIL_REPORTS = "email_reports",

  CASHIER_2ND = 'cashier_2nd',
}

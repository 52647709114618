import { RunMode } from '~~/interface/Pos';
import { JobAction, JobItemStatus } from '~~/interface/Job';
import _ from "lodash";
import { defineStore } from "pinia";
import {
  getDatabase, set, update,  ref, onValue, child, onChildAdded,
  getFirestore, doc, setDoc
} from "firebase/database";


import axios from 'axios';

import dayjs from "dayjs";
import { SETTING_ID } from '~~/interface/Setting';




export const useJobsStore = defineStore("jobs", {
  state: () => ({
    jobs: [],
    jobsHolder: {} as any,
    tables: {},  // start date, end date, items
    tables_v2: {},  // money summary , rounding

    tablesInfo: {},   // tables state lock info ,setting discount bill

    uuidItems: {},   // uuid items map
    nonce: _.random(100, 99999999).toString(),

    settings: {
      sc: [0, 0],
      dc2: [0, 0],
      tax: [0, 0],
    },
    openShop: null,
    delayComputeFn: () => { },
    paidAmount: 0,
  }),
  getters: {
    itemsCombined(state) {
      return function (table: any, { bill, sort, limit, offset }) {
        
        let items: any = [];
        let itemsToBeAdded = [];

        if (table && state.tables[table] && state.tables[table].items) {
          itemsToBeAdded = state.tables[table].items;
        }


        if (bill !== 'all') {
          itemsToBeAdded= itemsToBeAdded.filter((item) => item.bill == bill)
        
        }

        itemsToBeAdded.forEach((item) => {


          let itemCloned = _.cloneDeep(item)
          itemCloned.key = utilsItemGenKey(itemCloned)
          let index = items.findIndex((i: any) => i.key === itemCloned.key)
          if (index > -1) {
            items[index].qty += item.qty
            items[index].subTotal += item.subTotal
            items[index].extendedPrice += item.extendedPrice
            items[index].extendedPriceWithDiscount += item.extendedPriceWithDiscount

            items[index].dc1[2] = items[index].dc1[2] + item.dc1[2]
            items[index].sc[2] = items[index].sc[2] + item.sc[2]
            items[index].dc2[2] = items[index].dc2[2] + item.dc2[2]
            items[index].cc[2] = items[index].cc[2] + item.cc[2]
            items[index].tax[2] = items[index].tax[2] + item.tax[2]

            items[index].amount += item.amount



          } else {
            items.push(itemCloned)
          }

        })

        // if (bill != 'all') {
        //   items = items.filter((item) => item.bill == bill)
        // }



        return items;
      }
    },
    getStautsByUuid(state) {
      return function (uuid) {
        return state.uuidItems[uuid].status;
      }
    },
    getItemByUuid(state) {
      return function (uuid) {
        return state.uuidItems[uuid];
      }
    },
    getUuidItemsByTable(state) {
      return function (table, status) {
        let uuidItems = _.filter(state.uuidItems, (item) => item.table == table && item.status == status)
        return uuidItems;
      }
    },
    getBusinessDate(state) {
      let openObj = _.find(state.jobs, (job) => job.action == JobAction.OPEN)
      if (openObj) {
        return {
          businessDate: openObj.businessDate,
          shift: openObj.shift,
          timestamp: openObj.timestamp,
          nonce: openObj.nonce
        }
      } else {
        return null;
      }

    },
    getOpenJob(state) {
      let openObj = _.find(state.jobs, (job) => job.action == JobAction.OPEN)
      if (openObj) {
        return {
          ...openObj,
        }
      } else {
        return null;
      }

    },
    getCloseJob(state) {
      let closeObj = _.find(state.jobs, (job) => job.action == JobAction.CLOSE)
      if (closeObj) {
        return {
          ...closeObj,
        }
      } else {
        return null;
      }

    },

    computeMoneyOneTable(state) {
      return function (table, roundingType:string) {
        return utilsItemComputeOneTable({...state.tables[table], roundingType})

      }
    },
    isOpenShopCrossDate(){      
    
      const businessDate = dayjs(this.openShop?.timestamp).add(1, 'day').add(7, 'hour')
      const currentDate = dayjs()
      if (dayjs(businessDate).isBefore(currentDate)) {
        console.log("open over date...")
        return true
       
      }
      return false
    }
  },
  actions: {
    init() {
      setTimeout(() => {
        
        this.setupSettings()
      }, 4000);
      this.watch()
      this.watchHandshake()
      this.delayCompute()

      this.watchMoneyTableState()
      setTimeout(() => {
        
        this.setupSettings()
      }, 8000);
    },
    setupSettings() {
      let settingStore = useSettingsStore()
      let sc = settingStore.getValueBysettingId(SETTING_ID.SERVICE_CHARGE, 0)
      let dc2 = settingStore.getValueBysettingId(SETTING_ID.DISCOUNT_BILL, 0)
      let taxMode = settingStore.getValueBysettingId(SETTING_ID.TAX_MODE, 0)
      let taxValue = settingStore.getValueBysettingId(SETTING_ID.TAX_VALUE, 0)

      this.settings = {
        sc: [0, parseInt(sc)],
        dc2: [0, parseInt(dc2)],
        tax: [parseInt(taxMode), parseInt(taxValue)]
      }

        // this.delayCompute()
        this.delayComputeFn()
    },
    delayCompute() {
      const computeFn = _.debounce(this.compuet, 200)
      this.delayComputeFn = computeFn
      let previousJobsRef = {}
      this.$subscribe((mutation, state) => {

        //if previousJobsRef changed 
        if (previousJobsRef != state.jobsHolder) {
          previousJobsRef = state.jobsHolder

          computeFn()
        }




      })
    },
    //step 1
    add(job, syncToCloud = false) {

      if (this.jobsHolder[job.timestamp] == undefined) {
        this.jobsHolder = {
          ...this.jobsHolder,
          [job.jobId]: job
        }

        // this.jobsHolder[job.timestamp] = job

      }

    },

    rePrint(job) {

      // if (this.jobsHolder[job.timestamp] == undefined) {
      //   this.jobsHolder = {
      //     ...this.jobsHolder,
      //     [job.jobId]: job
      //   }

      //   // this.jobsHolder[job.timestamp] = job

      // }

    },

    addFromLocalhost(job) {

      if (this.jobsHolder[job.timestamp] == undefined) {
        this.jobsHolder = {
          ...this.jobsHolder,
          [job.timestamp]: job
        }
      }
    },

    compuet() {
      console.log('compute', new Date().toString())
      // const _jobs = Object.values(this.jobsHolder)
      const _jobs = _.values(this.jobsHolder)
      this.jobs = _.sortBy(_jobs, ['timestamp'])
      if (this.openShop == null) {
        this.openShop = this.jobs.find((job) => job.action == JobAction.OPEN)
      }


      let jobchain = new JobChain(this.jobs);
      this.tables = jobchain.getTables();

      const posStore = usePosStore();
     
      const tableState = posStore.tableState   


      const settingsStore = useSettingsStore();
      const channelSettings = settingsStore.getValueBysettingId("channels", []);
      console.log("channelSettings", channelSettings)

      // combine tables info service charge, discount bill, tax
      new TablesBuilder().computeTableItems(this.tables, this.tablesInfo, this.settings, tableState, channelSettings);
      this.uuidItems = jobchain.getItems();


      
            
      const roundingType =  settingsStore.getRoundingType
   


      let tables_v2 = computeTablesMap(this.tables, { roundingType})
      this.tables_v2 = tables_v2

      let amount = computePaidAmount(this.jobs);
      this.paidAmount = amount;
    },


    // Realtime DB
    watch() {


      let fb_realtimeDatabase = useFirebase()
      const jobChainRef = fb_realtimeDatabase.jobchainRef()

      onChildAdded(jobChainRef, (snapshot) => {
        const jobFromCloud = snapshot.val();


        // if nonce same, then not push to store
        if (jobFromCloud.nonce == this.nonce) {
          return
        }
        // console.log(jobFromCloud ,'jobFromCloud');

        this.add(jobFromCloud, false)
      });


    },

    watchLocal() {
      return

      let timer = setTimeout(fetchJobsLocal, 1000);
      async function fetchJobsLocal() {

        try {
          const res = await axios.get('http://localhost:3000/jobs')
          const jobs = res.data.jobs;
          jobs.forEach((job) => {
            this.addFromLocalhost(job);
          })

        } catch (error) {
          console.log(error)
        }




        timer = setTimeout(fetchJobsLocal, 1000);
      }
    },


    // handshake
    async watchHandshake() {


      let mode = useRunningMode().getMode()

      if (mode === RunMode.MAIN_CASHIER) {

        let handshakeRef = useFirebase().handshakeRef()
        onChildAdded(handshakeRef, (snapshot) => {
          const request = snapshot.val();

          if (request == 1) {
            let newRef = child(handshakeRef, snapshot.key)
            set(newRef, 2)
            setTimeout(() => {
              set(newRef, null)
            }, 2000);
          }

        });

        let jobRequestRef = useFirebase().jobRequestRef()
        onChildAdded(jobRequestRef, (snapshot) => {

          const job = snapshot.val();
          this.add(job, true)
          let newRef = child(jobRequestRef, snapshot.key)
          set(newRef, null)

        });
      }

    },


    // step2 - options
    setOptions(table, node, payload) {

      let fb_realtimeDatabase = useFirebase()
      const moneyTableRef = fb_realtimeDatabase.tableMoneyStateRef()

      let tableNodeRef = child(moneyTableRef, table)
      let nodeRef = child(tableNodeRef, node)
      set(nodeRef, payload)
      //----
      let tablesStateNodeRef = fb_realtimeDatabase.tableStateRef()
      let tableStateNodeRef = child(tablesStateNodeRef, table)
      update(tableStateNodeRef, { [node]: payload })
    },
    clearOptions(table, node) {
      let fb_realtimeDatabase = useFirebase()
      const moneyTableRef = fb_realtimeDatabase.tableMoneyStateRef()
      let tableNodeRef = child(moneyTableRef, table)
      set(tableNodeRef, null)
    },
    watchMoneyTableState() {

      let fb_realtimeDatabase = useFirebase()
      const moneyTableRef = fb_realtimeDatabase.tableMoneyStateRef()

      onValue(moneyTableRef, (snapshot) => {
        
        const data = snapshot.val();
        this.tablesInfo = data || {}

        // if (!this.tablesInfo[table]) {
        //   this.tablesInfo[table] = {};
        // }
        // this.tablesInfo[table][node] = payload;
        this.delayComputeFn()
        // new TablesBuilder().computeTableItems(this.tables, this.tablesInfo);

      });



    },

    //step3 - update status


    async syncJobsToCloud() {


      let _jobs = (this.jobsHolder)


      let data = {
        jobs: _jobs,
        storeId: useStoreId(),
        businessDate: this.openShop.businessDate,
        shift: this.openShop.shift,
        nonce: this.nonce,
        startedAt: this.openShop.timestamp,
        closedAt: dayjs().toISOString()
      }

      let { addDayJobs } = useFirebaseFirestore()

      return addDayJobs(`${data.businessDate}:${data.shift}`, data)


    },


    // close pos
    async clearJobs(reload = false) {

      this.jobs = [];
      let fb_realtimeDatabase = useFirebase()
      const jobChainRef = fb_realtimeDatabase.jobchainRef()

      await set(jobChainRef, null)
      if (reload) {
        window.location.href = "/";
      }
    }

  },
});







//---------------------- jobchain.js ----------------------//




function computeTablesMap(tablesMap, {  roundingType }) {

  let _res = {}
  _.forEach(tablesMap, (table, index) => {

    const { subTotal, dc1, sc, dc2, tax, cc, rounding, grandTotal } = utilsItemComputeSummary(table.items, roundingType)


    _res[index] = {
      subTotal: subTotal,
      dc1: dc1,
      sc: sc,
      dc2: dc2,
      cc:cc,
      tax: tax,
      rounding: rounding,
      grandTotal: grandTotal + rounding,
      itemsLength: table.items.length,
    }
  })




  return _res

}


function computePaidAmount(jobs)  {
	const payJobs = _.filter(jobs, (job) => job.action === "PAY");
	const sum = _.sumBy(_.flatMapDeep(payJobs, 'payment'), 'amount');	
	return sum || 0;
    }





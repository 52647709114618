<template>
  <VitePwaManifest></VitePwaManifest>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
//check if route is login path ?
localStorage.setItem("nuxt-color-mode", "light")
function isLoginPage() {
  if (process.client) {
    var loginPagePath = "/login"; // replace with the path of your login page
    return window.location.pathname === loginPagePath;
  }
}

function isSignup() {
  if (process.client) {
    var signupPagePath = "/signup"; // replace with the path of your login page
    return window.location.pathname === signupPagePath;
  }
}

function isExpired() {
  if (process.client) {
    var expiredPagePath = "/expired"; // replace with the path of your login page
    return window.location.pathname === expiredPagePath;
  }
}

if (isLoginPage() || isSignup() || isExpired()) {
  // do something
} else {
  // do something else
  useRouter().push("/");
}
</script>

<style>
select {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;  
}
</style>
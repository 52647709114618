

export const useOffline = () => {

    function save(key, type, payload) {
        //save item to local storage
        localStorage.setItem(key, JSON.stringify(payload))
    }

    function release(key) {
        //remove item from local storage
        localStorage.removeItem(key)
    }

    function getItemsByType(type) {

        let items = [];
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            try {
                let item = JSON.parse(localStorage.getItem(key));
                if (item?.type == type) {
                    items.push(item)
                }
            } catch (error) {

            }
        }
        return items
    }


    return {
        save,
        release,
        getItemsByType
    }
}

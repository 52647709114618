export const convertImgUrlToBase64 = async (url) => {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                resolve(reader.result);
            };
            reader.onerror = function() {
                reject(new Error("Failed to read blob data"));
            };
            reader.readAsDataURL(xhr.response);
        };
        
        xhr.onerror = function() {
            reject(new Error("Failed to fetch the URL"));
        };

        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    });
}

// convertImgUrlToBase64('https://assets.entrepreneur.com/content/3x2/2000/1602623277-GettyImages-1204099658.jpg', function(dataUrl){
//                 console.log('RESULT:',dataUrl);
// });

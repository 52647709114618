export const useEmployeePin = () => {
  return {
    getEmployee: getEmployee,
    getEmployeeFull: getEmployeeFull,
  };

  function getEmployee() {
    return {
      name: useEmployeePinStore().employee.name,
      employeeId: useEmployeePinStore().employee.employeeId,
    };
  }

  function getEmployeeFull() {
    return useEmployeePinStore().employee;
  }
};

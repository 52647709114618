import * as Sentry from '@sentry/vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import fbApp  from "../../../firebaseInstance"


export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: { sentry } } = useRuntimeConfig()


  if (!sentry.dsn || process.env.NODE_ENV !== 'production') {
    console.log('sentry not initialized since environment is not production');
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    release: 'xtable-webapp@1.0.0',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    //tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  //SET USER
  const auth = getAuth(fbApp.app);
  const shopStore = useShopStore()

  watch(()=> shopStore.storeId, (a,b) => { 
    onAuthStateChanged(auth,(user) => {
      if (user) {             
        Sentry.setUser({
          id: shopStore.storeId,
          username: `${user.email}`,         
        })
      } else {
        console.log("user is not signed in")      
      }
    });
})  

  console.log('Sentry is initialized');
})

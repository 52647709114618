import { PiniaPluginContext } from 'pinia'


let alreadyRun = false
function TimeChangeDetect({ store }: PiniaPluginContext) {
    return
    if (alreadyRun)
        return



    function timeChanged(delta) {
        // Whatever
        //  

        alert('The system time has changed. The POS must be reloaded.')

        //reload the page
        window.location.reload(true);

    }

    var oldTime = new Date(),
        newTime = new Date(),
        timeDiff = newTime - oldTime;
    setInterval(function timeChecker() {

        newTime = new Date()
        timeDiff = newTime - oldTime;

        oldTime = newTime;

        if (Math.abs(timeDiff) >= 1000 * 4 * 60) { // Five second leniency
            timeChanged(timeDiff);
        }
    }, 3000);

    alreadyRun = true
}

export default defineNuxtPlugin(({ $pinia }) => {
    $pinia.use(TimeChangeDetect)
})
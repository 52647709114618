export default class PrinterService {

    printerMap = {};
    constructor() {
      if (PrinterService.instance) {
        return PrinterService.instance;
      }
      PrinterService.instance = this;
    }
  
    getPrinter (item) {
      return this.printerMap[item] || {}
    }
  
    setItemsPrinter(items) {
       this.printerMap = { 
            ...this.printerMap,
            ...items
       }

    }
  }
  
import { RunMode } from '~~/interface/Pos';


import { child, set } from 'firebase/database';
import { PiniaPluginContext } from 'pinia'
import _ from 'lodash';

function MobileItemsPlugin({ store }: PiniaPluginContext) {

  if (store.$id == 'jobs') {
    const runmode = useRunningMode().getMode()
    let previousTablesStata = null
    let firstLoad = true
    let syncToFirebaseLazy = _.debounce(syncToFirebase, 1500)
    
    runmode == RunMode.MAIN_CASHIER && store.$subscribe((mutation, state) => {

      if (previousTablesStata != state.tables) {
        previousTablesStata = state.tables
        syncToFirebaseLazy()

      }

    })

    function syncToFirebase() {


      if(firstLoad) { 
        firstLoad = false
        return
      }

      const tablesRef = useFirebase().tablesRef()
      //sync tables
      set(tablesRef, previousTablesStata).then(() => {
        console.log('tables syncToFirebase')
      })

      //sync live summary
      const liveRef = useFirebase()._createRef("live")
      const summary = computeSummary(previousTablesStata)
      console.log('summary', summary)
      set(liveRef, summary).then(() => {
        console.log('summary syncToFirebase')
      })
    }


    function computeSummary(data: {}) {

     const jobs = store.$state.jobs || [];
      const lastJob = jobs[jobs.length - 1];
      const lastupdate = lastJob?.timestamp || Date.now();

      const grandTotal = store.$state.paidAmount;

      let summary = {
        totalTables: 0,
        totalGuests: 0,
        totalAmount: 0,
        lastupdate,
        grandTotal,
      };
      _.forEach(data, (table: any) => {

        let amount = _.sumBy(table.items, 'amount')
        summary.totalTables += 1
        summary.totalGuests += table.guests || 0
        summary.totalAmount += amount
        
      })

      return summary;

    }
  }

}

export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(MobileItemsPlugin)
})


import dataservice from '../../core/services/dataservice';

import { child, set } from 'firebase/database';
import { PiniaPluginContext } from 'pinia'


function MyPiniaPlugin({ store }: PiniaPluginContext) {


  if (store.$id == 'receipts') {
    const offlineService = useOffline()
    const fb_realtimeDatabase = useFirebase()

    // jobs from my machine sync to cloud
    store.$onAction(({
      name, // name of the action
      store, // store instance, same as `someStore`
      args, // array of parameters passed to the action
      after, // hook after the action returns or resolves
      onError, // hook if the action throws or rejects
    }) => {

      
      let receipt = args[0];
      let syncToCloud = args[1];
      if (name == 'add' && syncToCloud) {
        saveToCloud(receipt)
        saveAnalytics(receipt)
      }

    })

    function saveToCloud(receipt) {
      let key = receipt.createdAt.replace('.', '_');
      let type = 'receipt';
      let payload = {
        data: {
          ...receipt,
          nonce: store.nonce
        },
        type: type,
        key: key
      };
      offlineService.save(key, type, payload)
      dataservice.receipts.post(payload.data).then(() => {
        offlineService.release(key)
      }).catch((err) => {
        
        console.log(err)
      })

    }

    function saveAnalytics(receipt) {
        let storeId = receipt.storeId;
        let businessDate = receipt.businessDate;
        let _activeStoresRef =  useFirebaseAnalytic().activeStoresRef()  
        let storeAvticeRef = child(_activeStoresRef, `${businessDate}/${storeId}`) 
        set(storeAvticeRef,   true)
    }

  }

}

export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(MyPiniaPlugin)
})
import { set } from 'firebase/database';
import _ from 'lodash';
import { PiniaPluginContext } from 'pinia';
import { RunMode } from '~~/interface/Pos';

function TableMetaPlugin({ store }: PiniaPluginContext) {
  if (store.$id == "jobs") {
    const runmode = useRunningMode().getMode();
    let previousTableState = null;
    let firstLoad = true;
    let syncToFirebaseLazy = _.debounce(syncToFirebase, 1500);

    if (runmode == RunMode.MAIN_CASHIER) {
      store.$subscribe((mutation, state) => {
        if (previousTableState != state.tables) {
          previousTableState = state.tables;
          syncToFirebaseLazy();
        }
      });
    }


    function syncToFirebase() {
      if (firstLoad) {
        firstLoad = false;
        return;
      }

      const tableData = store.$state.tables // start date, end date, items
      const tableCalculated = store.$state.tables_v2 // money summary , rounding
      // todo get the state table eg. lock or not

      let _res = {}

      //* combine the data and calculated
      _.forEach(tableData, (table, index) => {
        
        const { subTotal, dc1, sc, dc2, cc, tax, rounding, grandTotal } = tableCalculated[index];
    
        _res[index] = {
          guests: table.guests, 
          startDate:table.startDate,
          subTotal: subTotal,
          dc1: dc1,
          sc: sc,
          dc2: dc2,
          cc:cc,
          tax: tax,
          rounding: rounding,
          grandTotal: grandTotal,
          itemsLength: table.items.length,
        }
      })
    
      //sync tableMeta
      const tableMetaRef = useFirebase().tableMetaRef();

      set(tableMetaRef, _res).then(() => {
        console.log("tables syncToFirebase");
      });
    }

  }
}

export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(TableMetaPlugin);
});

import { child, set } from "firebase/database";
import { doc, setDoc } from "firebase/firestore";
import { PiniaPluginContext } from "pinia";
import { JobAction } from "@/interface/job";
import dayjs from "dayjs"

function MyPiniaPlugin({ store }: PiniaPluginContext) {
  if (store.$id == "jobs") {
    const offlineService = useOffline();
    const firestoreDb = useFirebaseFirestore();

    //window.ABC_RICE = store
    setTimeout(() => {
      restore()
    }, 6000);
    // jobs from my machine sync to cloud
    store.$onAction(
      ({
        name, // name of the action
        store, // store instance, same as `someStore`
        args, // array of parameters passed to the action
        after, // hook after the action returns or resolves
        onError, // hook if the action throws or rejects
      }) => {
        let job = args[0];
        let syncToCloud = args[1];
        if (name == "add" && syncToCloud) {
          if (job.action == JobAction.SET_PRICE) {
            saveJobToCloud(job);
            console.log("check plugin change product price", job);
          }
        }
      }
    );

    function saveJobToCloud(job) {
      const storeId = useStoreId();
      const addTime = dayjs(job.timestamp).add(1,'second').toISOString()
      const keyReplace = addTime.replace(".", "_");
      let key = `${keyReplace}`;
      let type = "changeProductPrice";
      let payload = {
        data: {
          ...job,
          storeId,
          nonce: store.nonce,
        },
        type: type,
        key: key,
      };

  
      offlineService.save(key, type, payload);
      const changePriceRef = firestoreDb.changeProductPriceCollectionRef();
      const docRef = doc(changePriceRef, key);

      setDoc(docRef, payload)
        .then(() => offlineService.release(key))
        .catch((e) => {
          console.log(e);
        });
    }

    function restore() {
      let jobs = offlineService.getItemsByType("changeProductPrice");

      const storeId = useStoreId();
      jobs.forEach((job) => {
        if (job.data.storeId != storeId) {
          return;
        }
        const changePriceRef = firestoreDb.changeProductPriceCollectionRef();
        const docRef = doc(changePriceRef, job.key);
        setDoc(docRef, job.data)
          .then(() => offlineService.release(job.key))
          .catch((e) => {
            console.log(e);
          });
      });
    }
  }
}

export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(MyPiniaPlugin);
});
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDBY1O-rGfQJtA84pxCddo02FBxQP1heTE",
  authDomain: "xtable-882df.firebaseapp.com",
  databaseURL: "https://xtable-882df-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "xtable-882df",
  storageBucket: "xtable-882df.appspot.com",
  messagingSenderId: "500004913038",
  appId: "1:500004913038:web:5549a234259ae1f1fd6edf",
  measurementId: "G-3SHY0BKZ9W"
};

 
// Initialize Firebase
const app = initializeApp(firebaseConfig);


 export default  { app }
import { JobAction } from '~~/interface/Job';

import { child, set } from 'firebase/database';
import { PiniaPluginContext } from 'pinia'
import { RunMode } from "~~/interface/Pos";

const PrinterJob = ThermalPrinterJob


function MyPiniaPlugin({ store }: PiniaPluginContext) {



  if (store.$id == 'jobs') {
    // jobs from my machine sync to cloud
    store.$onAction((action) => {
      let job = action.args[0];
      let syncToCloud = action.args[1];
      let runMode = useRunningMode().getMode();
      if (action.name == 'add' && syncToCloud && job.print  ) {     
        if(runMode !== RunMode.CASHIER_2ND){
          console.log("check job", job)
          printJob(job)
        }else{
          console.log("print job test in cashier2 mode..")
        }  
         
      }
      if (action.name == 'rePrint'   ) {       
        rePrintJob(job)
      }
    })
  }


  if (store.$id == 'receipts') {
    // jobs from my machine sync to cloud
    store.$onAction((action) => {
      let receipt = action.args[0];
      let syncToCloud = action.args[1];
      if (action.name == 'add' && syncToCloud  ) {
        printReceipt(receipt)
      }
    })
  }

  

}

export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(MyPiniaPlugin)
})



function printJob(job) {
   
  console.log('thermal printer JOB run in plugin',)
  
  let printerService = new PrinterService()
  switch (job.action) {
    case JobAction.ADD:      
      new PrinterJob().printCustomMode(job, printerService) .print()
      //new PrinterJob().printOneByOne(job, printerService) .print()
      break;
    case JobAction.MOVE:
      new PrinterJob().printMoveItems(job, printerService) .print()
      break;

    case JobAction.VOID:
      new PrinterJob().printVoidItems(job, printerService) .print()
      break;  

    default:
      break;
  }
}

function rePrintJob(job) {
   
  console.log('thermal printer JOB run in plugin',)
  
  let printerService = new PrinterService()
  switch (job.action) {
    case JobAction.REPRINT:
      new PrinterJob().rePrintJob(job, printerService) .print()
      break;

    default:
      break;
  }
}


function printReceipt(receipt) { 
  console.log('thermal printer receipt run in plugin',)
}

function printDashboard(payload) {

}


import { child, set } from 'firebase/database';
import { PiniaPluginContext } from 'pinia'
import { JobAction } from "@/interface/job";
function MyPiniaPlugin({ store }: PiniaPluginContext) {


  if (store.$id == 'jobs') {
    const offlineService = useOffline()
    const fb_realtimeDatabase = useFirebase()    

    //window.ABC_RICE = store
    setTimeout(() => {
        restore()
    }, 6000);
    // jobs from my machine sync to cloud
    store.$onAction(({
      name, // name of the action
      store, // store instance, same as `someStore`
      args, // array of parameters passed to the action
      after, // hook after the action returns or resolves
      onError, // hook if the action throws or rejects
    }) => {


      let job = args[0];
      let syncToCloud = args[1];
      if (name == 'add' && syncToCloud) {
        saveJobToCloud(job)
      }

    })

    function saveJobToCloud(job) {      
      let key = job.timestamp.replace('.', '_');
      let type = 'job';
      let payload = {
        data: {
          ...job,
          nonce: store.nonce
        },
        type: type,
        key: key
      };
      offlineService.save(key, type, payload)
      const jobChainRef = fb_realtimeDatabase.jobchainRef()
      let job_timestamp_ref = child(jobChainRef, key)

      set(job_timestamp_ref, payload.data).then(() => {
        offlineService.release(key)
      })

    }


    function restore() { 
       
      let jobs = offlineService.getItemsByType('job')
      const storeId = useStoreId()
      jobs.forEach(job => {
        if(job.data.storeId != storeId){
          return
        }
        const jobChainRef = fb_realtimeDatabase.jobchainRef()
        let job_timestamp_ref = child(jobChainRef, job.key)
        set(job_timestamp_ref, job.data).then(() => {
          offlineService.release(job.key)
        })
      })
    }


  }

}

export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(MyPiniaPlugin)
})


export const setCookie = (name:string, value:string, days:number, domain:string = "")=> {
    
    let expires = "";
    const _domain = `; domain=${domain}`; //.xtable.co
    const secureFlag = "; secure"; 
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    
    document.cookie = `${name}=${(value || "")} ${expires} ${_domain}; path=/ ${secureFlag}`;
}

export const getCookie = (name:string)=> {
    var nameEQ = name + "=";
    var cookies = document.cookie.split(';');
    for(var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) == 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }
    return null;
}

export const removeCookie = (name:string, domain:string)=> {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=" + domain + ";path=/;"; // Set expiration date in the past
}
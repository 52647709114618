export enum PRINTER_JOB_MODE {
  ALL='ALL',
  SUMMARY = 'SUMMARY',
  ONE_BY_ONE = 'ONE_BY_ONE',
  ONE_QTY_EACH = 'ONE_QTY_EACH',
  BY_PRODUCT_GROUP = 'BY_PRODUCT_GROUP',
  SUMMARY_AND_ONE_BY_ONE = 'SUMMARY_AND_ONE_BY_ONE',
  BY_PRODUCT_GROUP_AND_ONE_BY_ONE = 'BY_PRODUCT_GROUP_AND_ONE_BY_ONE',
  ONE_QTY_EACH_ITEM = 'ONE_QTY_EACH_ITEM',
  TURN_OFF = 'TURN_OFF',
}

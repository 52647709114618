

import axios, { AxiosResponse } from 'axios';
import _ from 'lodash'

export default class ThermalPrinterBase {
    // apiUrl: string = "http://192.168.0.181:9090";
    // apiUrl: string = "http://192.168.2.130:9090";
    apiUrl: string = "http://localhost:9090";
    path: string = "/thermal"
    print_queues = []


    // ตรวจสอบ item ที่ใช้
    getPrintersThatUsed(items: any) {
        const printers: any = []
        items.forEach((item: any) => {
            printers.push(item.printer)
        })
        return  _.compact(printers)
    }

    print() {
        this.print_queues.forEach(cmd => {
            console.log('send to printer', cmd)
            axios.post(this.apiUrl + this.path + '/document', cmd)
                .then((response: AxiosResponse) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        })

    }
}
import _ from "lodash";

// auth ,  restore, fetch, watch , ready
export const useEmployeePinStore = defineStore("employeePin", {
  state: () => {
    return {
      active: true,
      employeeList: [],
      employee: null,
      isPageAllowed: false,
    };
  },
  getters: {
    getCurrentEmployee(state) {
      return state.employee;
    },
  },
  actions: {
    init() {
      let store = this;
      this.$subscribe(() => {
        store.checkPermissionPage();
      });
    },
    lockScreen() {
      this.employee = null;
      this.active = true;
    },
    findEmployeeByPin(pin) {
      let emp = _.find(this.employeeList, { pin: pin });
      if (emp) {
        this.employee = emp;
        this.active = false;
        return true;
      } else {
        this.employee = null;
        this.active = true;
        return false;
      }
    },
    checkPermissionPage() {
      let path = useRouter().currentRoute.value.path;

      const resultCheck = usePermission().checkPermissionPage(path);
      if (!resultCheck) {
        return navigateTo("/notallowed?from=" + path);
      }
    },
  },
});

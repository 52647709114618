import dayjs from "dayjs";
import {useShopStore } from "../modules/core/store/shop"

export default defineNuxtRouteMiddleware((to, from) => {  
    const authStore = useShopStore()  
    let expireDate = authStore.expireDate;    
    let addDayToExpireDate = dayjs(expireDate).add(31, 'hours'); //  1 day and 7 hours

    if(!expireDate){
        return
    }

    let currentDate = dayjs(); 
    if (dayjs(addDayToExpireDate).isBefore(currentDate)) {
        console.log("Expire date!!!")
        // window.location.href = "/expired"   
        // return
    } 

});

import dataservice from '../../core/services/dataservice';

import { child, set, update } from 'firebase/database';
import { PiniaPluginContext } from 'pinia'

function MyPiniaPlugin({ store }: PiniaPluginContext) {

  let previousState = {}
  
  if (store.$id == 'pos') {
     
    store.$onAction(({
      name, // name of the action
      store, // store instance, same as `someStore`
      args, // array of parameters passed to the action
      after, // hook after the action returns or resolves
      onError, // hook if the action throws or rejects
    }) => {

      let table = args[0];
      const actionName = ['printPreview','printPreviewFromMobile', 'lockTable', 'unlockTable', 'setChannel', 'clearChannel', 'setGuest', 'clearGuest','setServiceChange', 'setNote', 'clearNote']
      if (actionName.includes(name)) {
        after((dataReturn)=>{         
          saveJobToCloud(table, dataReturn[table])
        })         
      }     
      
    })
  }

let tablesStateRef = null

function saveJobToCloud(table, payload) {
  
  if(tablesStateRef == null) { 
  let  fbInstance = useFirebase()

    tablesStateRef = fbInstance.tableStateRef()
  }
 update(tablesStateRef, { [table]: payload}) 
}



}

function findChangedProperties(original, modified) {
  let changedProperties = {};
  for (let prop in modified) {
    if (original[prop] !== modified[prop]) {
      changedProperties[prop] = modified[prop]
    }
  }
  return changedProperties;
}

export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(MyPiniaPlugin)
})
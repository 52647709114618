import { RunMode } from "../../../interface/Pos";

export const useRunningMode = () => {
  // MODE 10 == main cashier, 11 Second Cashier,  -1 not set

  let currentMode = null; // RunMode.UNASSIGNED

  return {
    getMode,
    setMode,
    clear,
  };

  function getMode() {
    if (currentMode == null) {
      currentMode = localStorage.getItem("_mode") || RunMode.UNASSIGNED;
    }
    return currentMode;
  }

  function setMode(mode) {
    currentMode = mode;
    localStorage.setItem("_mode", mode);
  }

  function clear() {
    currentMode = RunMode.UNASSIGNED;
    localStorage.removeItem("_mode");
  }
};

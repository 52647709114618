import { useEmployeePin } from "./useEmployeePin";
import {
  EMPLOYEE_PATH_TO_PERMISSIONS,
  EMPLOYEE_PERMISSIONS_LINK_TO_PATH,
} from "~~/interface/EmployeePermission";
import _ from "lodash";

export const usePermission = () => {
  return {
    checkPermissionPage,
  };

  function checkPermissionPage(path) {
    if (!path) return;

    //let path =  useRouter().currentRoute.value.path
    let emp = useEmployeePin().getEmployeeFull();
    if (emp == null) {
      return true;
    }

    //IS ADMIN FOR FULL ACCESS
    if (emp.admin) {
      return true;
    }

    const jobsStore = useJobsStore();
    let EMP_PAGE = emp.pages_permissions || {};
    let PAGE_ALLOW_HOLDER = {};

    for (let key in EMP_PAGE) {
      if (key == "all" && EMP_PAGE["all"]) {
        PAGE_ALLOW_HOLDER[key] = true;
      } else {
        PAGE_ALLOW_HOLDER[key] = false;

        if (_.includes(path, key) && EMP_PAGE[key]) {
          PAGE_ALLOW_HOLDER[key] = true;
        }

        //If user haven't opened the store yet, display the Dashboard page.
        if(key=='dashboard'){
          PAGE_ALLOW_HOLDER[key] = true 
        }        
      }
    }

    //CURRENT PAGE PERMISSION
    const currPath = EMPLOYEE_PATH_TO_PERMISSIONS[path];

    if (PAGE_ALLOW_HOLDER[currPath] || PAGE_ALLOW_HOLDER["all"]) {
      return true;
    } else {
      return false;
      // return navigateTo('/notallowed?from=' + path,)
    }
  }
};

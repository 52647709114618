

//create CRUD class with axios
import axios, { AxiosResponse } from 'axios';
import DataService from './dataservice.base';


export default class InventoryTransactionDataService extends DataService {


    constructor(path :string) {
        super(path);
        
    }

    async getBalance() { 
        return await this.axios.get(`${this.apiUrl}${this.path}/balance?storeId=${this.storeId}`);
    }
    async reportSummaryRange(payload) { 
        if (!payload.storeId) {
            payload.storeId  = this.storeId;
        }
        return await this.axios.post(`${this.apiUrl}${this.path}/report/summary-range?storeId=${this.storeId}`, payload);
    }

    async reportStockItemByDate(payload) {

        if (!payload.storeId) {
            payload.storeId  = this.storeId; 
        }

        return await this.axios.post(`${this.apiUrl}${this.path}/report/stock-item-by-date`, payload)
        // return await this.axios.post(`${this.apiUrl}${this.path}/report/stock-item-by-date?storeId=${this.storeId}`, payload);
    }

    async posSold(payload) {

        if (!payload.storeId) {
            payload.storeId  = this.storeId; 
        }

        return await this.axios.post(`${this.apiUrl}${this.path}/pos-sold`, payload)
        // return await this.axios.post(`${this.apiUrl}${this.path}/report/stock-item-by-date?storeId=${this.storeId}`, payload);
    }
}
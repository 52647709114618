import _ from "lodash";
import { usePermission } from "../modules/core/composables/usePermission";
import {useAuthStore } from "../modules/core/store/auth"
import { getCookie } from "../modules/core/utils/cookie"

export default defineNuxtRouteMiddleware((to, from) => {
  if(to.path=="/login"){
    const cookieData = getCookie("xtable") || null
    const authStore = useAuthStore()  

    //Receive By Cookie
    if(cookieData){
      const parsedData = JSON.parse(cookieData)      
      authStore.uid = parsedData.uid   
      window.location.href = "/";
    }
  }


  if (to.meta?.freeAuth) {
    return;
  }


  //----   default path is ok
  if (to.path == "/") {
    return;
  }

  const resultCheck = usePermission().checkPermissionPage(to.path);
  if (!resultCheck) {
    return navigateTo("/notallowed?from=" + to.path);
  }
});

import { JobCard } from '~~/interface/Job';
import  _ from "lodash";
import { JobAction, JobCard, JobItemStatus } from "~~/interface/Job";
import { JobItem } from "~~/interface/Job";

export default class Jobs {
    jobs: Array<JobCard> = [];
    itemsUuid: { [uuid: string]: JobItem | null } = {};
    voidUuid = {};
    moveUuid = {};
    _tables = {}

    constructor(jobs) {
        this.jobs = jobs;
        this.compute();
    }

    compute() {

        // console.log(_)
        let sorted = _.sortBy(this.jobs, j => j.timestamp)


        _.forEach(sorted, (job: JobCard, index) => {

            try {
                switch (job.action) {

                    //status table
                    // case JobAction.QR_CODE_ORDER:
                    //     this.qrCodeOrder(job);
                    //     break;
                    // case JobAction.LOCK_TABLE:
                    //     this.lockTable(job);
                    //     break;
                    // case JobAction.PRINT_PREVIEW:
                    //     this.printPreview(job);
                    //     break;
                    case JobAction.SET_START_TIME:
                        this.setStartTime(job);
                        break;
                    case JobAction.SET_GUESTS:
                        this.setGuests(job);
                        break;

                    //items
                    case JobAction.ADD:
                        this.add(job);
                        break;
                    case JobAction.MOVE:
                        this.move(job);
                        break;
                    case JobAction.SPLIT_ITEM:
                        this.splitItem(job);
                        break;
                    case JobAction.SPLIT_BILL:
                        this.splitBill(job);
                        break;
                    case JobAction.SET_PRICE:
                        this.setPrice(job);
                        break;
                    case JobAction.SET_DISCOUNT:
                        this.setDiscount(job);
                        break;
                    case JobAction.VOID:
                        this.void(job);
                        break;
                    case JobAction.VOID_RECEIPT:
                        this.voidReceipt(job);
                    case JobAction.PAY:
                        this.pay(job);
                        break;
                }

            } catch (error) {


                console.error(error)
                console.error(job)
                console.error('index', index)

                throw error
            }
        });

    }

    getItems() {

        return this.itemsUuid
        // return {
        //     items: this.itemsUuid,
        //     moveUuid: this.moveUuid,
        //     voidUuid: this.voidUuid,
        // };
    }

    getTables() {

        //group items by table
        let tables = {};
        let tableJobId = {}
        let items = this.itemsUuid;
        _.forEach(items, (item) => {
            if (item.status == JobItemStatus.ACTIVE) {

                let table = item.table || '_unknown_'
                if (!tables[table]) {
                    tables[table] = {
                        items: [],
                        jobs: [], // jobid
                        ...this._tables[table]
                    };

                }
                tables[table].items.push(item);

                let k = `${table}-${item.jobId}`
                if (tableJobId[k] == undefined) {
                    tableJobId[k] = true
                    tables[table].jobs.push(item.jobId)
                }

            }
        });

        return tables;
    }





    add(job: JobCard) {


        _.forEach(job.items, (itemLineFromJob: ItemDatabase, i) => {


            this.itemsUuid[itemLineFromJob.uuid] = {
                ...itemLineFromJob,
                table: job.table,
                jobId: job.jobId,
                bill: 1,
                row: i * 10,
                status: JobItemStatus.ACTIVE,
                timestamp: job.timestamp,
                employeeId: job.employee.employeeId,
                employeeName: job.employee.name,
                discountItem: [0, 0, 0]
            };
        });

        if(this._tables[job.table] == undefined) { 
            this._tables[job.table] = {
                startDate: job.timestamp,
                guests: job.guests
            }
        }

        if (job.guests >= 1&& job.channel !== 'qrcode') {
            this._tables[job.table] = {
                startDate: job.timestamp,
                guests: job.guests
            }
        }


        // if (job.guests >= 1 ) {
        //     this._tables[job.table] = {
        //         startDate: job.timestamp,
        //         guests: job.guests
        //     }
        // }

       // this._tables[job.table] = {...this._tables[job.table]}

    }

    move(job: JobCard) {

        let oldTable = job.table[0];
        let newTable = job.table[1];


        //check the table is already taken or not, by find one item in new table if found, then the table is taken
        let isTableTaken = _.find(this.itemsUuid, (item) => {
            return item.table == newTable && item.status == JobItemStatus.ACTIVE
        });

        // if new table is empty, set start time and guests from old table
        if ( !isTableTaken  ) {


            // if( this._tables[newTable]  == undefined) { 
                
            //     this._tables[newTable] = {
            //         startDate: job.timestamp,
            //         guests:2
            //     }
            // }

            this._tables[newTable] = {
                startDate:job.timestamp, // this._tables[oldTable].startDate,
                guests: 2 //this._tables[oldTable].guests
            }
        }


        _.forEach(job.items, (itemLineFromJob) => {
            let item = this.itemsUuid[itemLineFromJob.uuid]
            if (item != null) {
                item.table = newTable
                item.jobId = job.jobId
                item.timestamp = job.timestamp
                item.status = JobItemStatus.ACTIVE

                this.itemsUuid[item.uuid] = item
            }
        });

         
        


    }

    splitItem(job: JobCard) {

        _.forEach(job.items, (itemLineFromJob) => {
            let thisItem = this.itemsUuid[itemLineFromJob.uuid];

            let newItem = _.cloneDeep(thisItem);
            newItem.uuid = itemLineFromJob.newUuid;
            newItem.newLine = true
            newItem.splited = true

            newItem.qty = Math.abs(itemLineFromJob.qty);
            newItem.row = thisItem.row + 1

            thisItem.qty = thisItem.qty - Math.abs(newItem.qty);
            if (thisItem.qty <= 0) {
                this.itemsUuid[thisItem.uuid].status = JobItemStatus.VOIDED;
            }

            this.itemsUuid[newItem.uuid] = newItem;

        });
    }
    splitBill(job: JobCard) {

        _.forEach(job.items, (itemLineFromJob) => {
            let thisItem = this.itemsUuid[itemLineFromJob.uuid];
            thisItem.bill = itemLineFromJob.bill;
        });
    }


    // void items
    void(job: JobCard) {
        _.forEach(job.items, (itemLineFromJob: ItemDatabase) => {
            this.itemsUuid[itemLineFromJob.uuid].status = JobItemStatus.VOIDED;
            this.itemsUuid[itemLineFromJob.uuid].reason = job.note
        });

    }

    voidReceipt(job: JobCard) {

        // find one that match hash
        let payment_job = _.find(this.jobs, (j) => {
            return j.hash == job.hash
        });

        //if found, void all items
        if (payment_job) {
            _.forEach(payment_job.items, (itemLineFromJob: ItemDatabase) => {
                this.itemsUuid[itemLineFromJob.uuid].status = JobItemStatus.VOIDED;
            });

        }
    }

    setPrice(job) {
        _.forEach(job.items, (itemLineFromJob) => {
            let thisItem = this.itemsUuid[itemLineFromJob.uuid];
            thisItem.price = itemLineFromJob.price;

            if(thisItem?.options2) { 
                thisItem.options2.forEach((option) => {
                    option.price = 0
                });
            }
        });

    }
    setDiscount(job) {
        _.forEach(job.items, (itemLineFromJob) => {
            let thisItem = this.itemsUuid[itemLineFromJob.uuid];
            thisItem.discountItem = itemLineFromJob.discountItem;
        });
    }

    pay(job: JobCard) {


        _.forEach(job.items, (itemLineFromJob: ItemDatabase) => {
            this.itemsUuid[itemLineFromJob.uuid].status = JobItemStatus.PAID;
        });




    }

    //status table methods
    setGuests(job: JobCard) {
        let table = job.table

        // if table is not in the list, add it
        if (!this._tables[table]) {
            return
        }

        // add guests to the table
        this._tables[table].guests = job.guests || 1
    }

    setStartTime(job: JobCard) {
        let table = job.table
        this._tables[table].startDate = job.value
    }


}

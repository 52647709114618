

//create CRUD class with axios
import axios, { AxiosResponse } from 'axios';
import ThermalPrinterBase from './ThermalPrinterBase';
import dayjs from 'dayjs'

import { SETTING_ID } from '~~/interface/Setting';
import { PRINTER_JOB_MODE } from '~~/interface/PrinterJobMode';

const PRINTER_NAME = 'pos8080'
const PRINTER_LIST = ['p0','p1', 'p2', 'p3', 'p4', 'p5']
const PRINTER_LIST_SETTING = [
    SETTING_ID.PRINTER_0_MODE,
    SETTING_ID.PRINTER_1_MODE,
    SETTING_ID.PRINTER_2_MODE,
    SETTING_ID.PRINTER_3_MODE,
    SETTING_ID.PRINTER_4_MODE,
    SETTING_ID.PRINTER_5_MODE,
]




export default class ThermalPrinterJob extends ThermalPrinterBase {
    printCustomMode(job, printerService) {
        console.log("start print custom mode..")
        const settingsStore = useSettingsStore();
        const productsStore = useProductsStore()
        const productGroupsHolder = productsStore.getProductGroupsByItemHolder()       
        

        //loop for printers 
        for (let index = 0; index < PRINTER_LIST.length; index++) {
            const printer = PRINTER_LIST[index];
            const PRINTER_MODE_SETTING_BY_PRINTER = PRINTER_LIST_SETTING[index]
 

            //filter items by printer
            const jobItems = job.items || []
            let items = jobItems.filter((item) => {
                let printerForThisItem = printerService.getPrinter(item.productId) || {}
                return printerForThisItem[printer] == true
            })  

            //if no item, skip
            if (items.length == 0) {
                continue
            }

            const printMode = settingsStore.getValueBysettingId(PRINTER_MODE_SETTING_BY_PRINTER, PRINTER_JOB_MODE.SUMMARY)            

            if (printMode == PRINTER_JOB_MODE.SUMMARY || printMode == PRINTER_JOB_MODE.ALL) {                
                this.buildSummaryOuput(job, printer, items, index)                
            }

            //One By Each
            if (printMode == PRINTER_JOB_MODE.ONE_BY_ONE || printMode ==  PRINTER_JOB_MODE.ALL) {                
                this.buildOneByOneOutput(job, printer, items, index)                
            }

            //One By Qty
            if (printMode == PRINTER_JOB_MODE.ONE_QTY_EACH || printMode ==  PRINTER_JOB_MODE.ALL) {                
                this.buildOneQtyEachOutput(job, printer, items, index)                
            }            

            //By product group
            //Build item group and loop for print by group
            if (printMode == PRINTER_JOB_MODE.BY_PRODUCT_GROUP || printMode ==  PRINTER_JOB_MODE.ALL) {                
                this.buildPrintByProductGroupOutput(job, printer, items,productGroupsHolder , index)                
            }

            //Summary & One by One
            if (printMode == PRINTER_JOB_MODE.SUMMARY_AND_ONE_BY_ONE) {                
                this.buildSummaryOuput(job, printer, items, index)
                this.buildOneByOneOutput(job, printer, items, index)                
            }

            //Product Group & One by One
            if (printMode == PRINTER_JOB_MODE.BY_PRODUCT_GROUP_AND_ONE_BY_ONE) {                
                this.buildPrintByProductGroupOutput(job, printer, items,productGroupsHolder , index)
                this.buildOneByOneOutput(job, printer, items, index)                
            }            
        }

        return this
    }

   
 

    // Reprint หรือ ตามอาหาร
    rePrintJob(job, printerService) {
        const settingsStore = useSettingsStore();
        const isShowItemPrice = settingsStore.getValueBysettingId(SETTING_ID.PRINTER_JOB_SHOW_PRICE, 0)

        //loop for printers 
        for (let index = 0; index < PRINTER_LIST.length; index++) {
            const printer = PRINTER_LIST[index];

            //filter items by printer
            let items = job.items.filter((item) => {
                let printerForThisItem = printerService.getPrinter(item.productId) || {}
                return printerForThisItem[printer] == true
            })

            //if no item, skip
            if (items.length == 0) {
                continue
            }


            let content = []

            // draw detail store
            content.push(
                {
                    type: 'text',
                    value: `printer ${printer} ${index}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 10,
                        fontStyle: 'normal',
                        align: 'center'
                    }
                },

                {
                    type: 'newline',
                    value: '10'
                },
                {
                    type: 'text',
                    value: `Date :  ${dayjs(job.timestamp).format('DD/MM/YYYY HH:mm:ss')}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'text',
                    value: `Employee :  ${job.employee.name}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'newline',
                    value: '20'
                },
                {
                    type: 'text',
                    value: `*** ${job.reprintType || 'Reprint'} ***`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 15,
                        fontStyle: 'bold',
                        align: 'center'
                    }
                },
                {
                    type: 'text',
                    value: `Table ${job.table}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 16,
                        fontStyle: 'bold',
                        align: 'center'
                    }
                },
                {
                    type: 'newline',
                    value: "10"
                },
                {
                    type: 'line'
                }
            )
            //draw items
            items.forEach(item => {
                content.push({
                    type: 'text2cols',
                    value: `${item.qty}   ${item.name} (${job.reprintType || 'Reprint'})`,
                    value2: `${isShowItemPrice ? item.price : ''}`,
                    // value2: ` `,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 16,
                        fontStyle: 'normal',
                    }
                },)

                if (item.options2 && item.options2.length > 0) {
                    item.options2.forEach(option => {
                        content.push({
                            type: 'text2cols',
                            value: `     - ${option.name}  x${option.qty}`,
                            value2: ` `,
                            style: {
                                fontFamily: 'Arial',
                                fontSize: 15,
                                fontStyle: 'normal',
                            }
                        })
                    })
                }

                if (item.comments) {
                    content.push({
                        type: 'text2cols',
                        value: `     # ${item.comments}`,
                        value2: ` `,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: 15,
                            fontStyle: 'normal',
                        }
                    })
                }

            })
            // draw line
            content.push({ type: 'line' })

            let cmd = {
                // printerName: PRINTER_NAME,
                printerPreset: index.toString(),
                timestamp: new Date().toISOString(),
                date: dayjs().format('YYYY-MM-DD'),
                jobId: new Date().valueOf().toString() + '_' + printer,
                content
            }
            this.print_queues.push(cmd)
        }

        return this
    }

    printOneEachQty(job) {

        // draw items
        let allprinters = this.getPrintersThatUsed(job.items)
        allprinters.forEach(i => {
            job.items.forEach(item => {

                let contents = []
                let cmd = {
                    printer: i,
                    contents: contents
                }
                //draw header
                contents.push({ type: 'text', text: `Date :  ${job.timestamp} ` })
                contents.push({ type: 'text', text: `Employee :  ${job.employee.name}` })

                //draw body
                contents.push({ type: 'text', text: `TABLE :  ${job.table}`, align: 'center', fontSize: 14, bold: true, fontFamily: 'Arial', italic: true, underline: true, strike: true, })

                //draw type new line
                contents.push({ type: 'line', line: 'single', thickness: 2, width: 1, align: 'center' })

                // text left and right in the same line
                contents.push({ type: 'text', text: `${item.qty} x ${item.name}`, align: 'left', fontSize: 14, })
                contents.push({ type: 'text', text: `${item.price}`, align: 'right', fontSize: 14, })

                // draw modifuer
                if (item.modifier) {
                    contents.push({ type: 'text', text: `  - ${item.modifier}`, fontSize: 12, italic: true, })
                }


                // draw note
                if (item.note) {
                    contents.push({ type: 'text', text: `${item.note}` })
                }
                //draw type new line
                contents.push({ type: 'line', line: 'single', thickness: 2, width: 1, align: 'center' })

                //send to printer
                this.print_queues.push(cmd)
            });

        })


    }

    printMoveItems(job: any, printerService) {
        const settingsStore = useSettingsStore();
        const canMoveItem = settingsStore.getValueBysettingId(SETTING_ID.PRINTER_JOB_MOVE_TABLE, false)
        if (!canMoveItem) return this;

        for (let index = 0; index < PRINTER_LIST.length; index++) {
            const printer = PRINTER_LIST[index];

            //filter items by printer
            let items = job.items.filter((item) => {
                let printerForThisItem = printerService.getPrinter(item.productId) || {}
                return printerForThisItem[printer] == true
            })

            if (items.length == 0) {
                continue
            }

            let content = []
            // draw detail header
            content.push(
                {
                    type: 'text',
                    value: `Date :  ${dayjs(job.timestamp).format('DD/MM/YYYY HH:mm:ss')}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'text',
                    value: `Employee :  ${job.employee.name}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'text',
                    value: `Printer :  ${printer}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'newline',
                    value: "10"
                }
            )

            // draw table move to
            content.push(
                {
                    type: 'text',
                    value: `Table ${job.table[0]} ย้ายไป ${job.table[1]}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 16,
                        fontStyle: 'bold',
                        align: 'center'
                    }
                },
                { type: 'newline', value: '10' },
                { type: 'line' },
                { type: 'newline', value: '10' }
            )

            // draw detail move
            content.push(
                {
                    type: 'text',
                    value: `**   หากมีรายการค้าง`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 10,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'text',
                    value: `**   ย้ายจากโต๊ะ ${job.table[0]} ==> ${job.table[1]}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 10,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                // {
                //     type: 'text',
                //     value: `**   (ย้ายบางรายการ ดังนี้)`,
                //     style: {
                //         fontFamily: 'Arial',
                //         fontSize: 10,
                //         fontStyle: 'normal',
                //         align: 'left'
                //     }
                // },
                {
                    type: 'newline',
                    value: '10'
                }
            )

            // draw items move
            items.forEach(item => {
                content.push(
                    {
                        type: 'text',
                        value: `${item.qty}   ${item.name}`,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: this.customFontSize(14),
                            fontStyle: 'normal',
                            align: 'left'
                        }
                    },
                    {
                        type: 'text',
                        value: `     - ย้าย (${job.table[0]} ==> ${job.table[1]})`,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: this.customFontSize(12),
                            fontStyle: 'normal',
                            align: 'left'
                        }
                    },
                )
            })
            content.push(
                {
                    type: 'newline', value: '10'
                },
                { type: 'line' }
            )

            let cmd = {
                printerPreset: index.toString(),
                timestamp: new Date().toISOString(),
                date: dayjs().format('YYYY-MM-DD'),
                jobId: new Date().valueOf().toString() + index,
                content,
            }
            this.print_queues.push(cmd)
        }

        return this
    }

    printVoidItems(job, printerService) {
        const settingsStore = useSettingsStore();
        const canVoidItem = settingsStore.getValueBysettingId(SETTING_ID.PRINTER_JOB_VOID_ITEMS, false)
        if (!canVoidItem) return this;

        //Void summary
        this.buildVoidSummary(job)

        for (let index = 0; index < PRINTER_LIST.length; index++) {
            const printer = PRINTER_LIST[index];

            //filter items by printer
            let items = job.items.filter((item) => {
                let printerForThisItem = printerService.getPrinter(item.productId) || {}
                return printerForThisItem[printer] == true
            })

            if (items.length == 0) {
                continue
            }

            console.log("void items=>", items)

            let content = []

            // draw detail header
            content.push(
                {
                    type: 'text',
                    value: `Date :  ${dayjs(job.timestamp).format('DD/MM/YYYY HH:mm:ss')}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'text',
                    value: `Employee :  ${job.employee.name}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'text',
                    value: `Printer :  ${printer.replace('p', '')}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'newline',
                    value: "10"
                },
                {
                    type: 'text',
                    value: `${job.table} (ยกเลิกรายการ)`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 14,
                        fontStyle: 'bold',
                        align: 'center'
                    }
                },
                {
                    type: 'newline',
                    value: "10"
                },
                { type: 'line' },
                {
                    type: 'newline',
                    value: "10"
                },
            )

            // draw item
            items.forEach(item => {
                content.push({
                    type: 'text',
                    value: `${item.qty}   ${item.name}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: this.customFontSize(12),
                        fontStyle: 'strikeOut',
                        align: 'left'
                    }
                })
            })

            //draw footer
            content.push(
                {
                    type: 'newline',
                    value: '10'
                },
                { type: 'line' },
                {
                    type: 'newline',
                    value: '10'
                },
                {
                    type: 'text',
                    value: `Reason : ${job.note}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: this.customFontSize(10),
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'newline',
                    value: '10'
                },
                {
                    type: 'text',
                    value: `Sign : _________________`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 10,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'newline',
                    value: '10'
                },
            )

            console.log("void item=>", content)
            let cmd = {
                printerPreset: index.toString(),
                timestamp: new Date().toISOString(),
                date: dayjs().format('YYYY-MM-DD'),
                jobId: new Date().valueOf().toString() + index,
                content
            }
            this.print_queues.push(cmd)

        }
        return this
    }


    // voided items to cashier
    buildVoidSummary(job) {
        let content = []

        // draw detail store
        content.push(
            {
                type: 'text',
                value: `*** Original Copy to Cashier ***`,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fontStyle: 'normal',
                    align: 'center'
                }
            },

            {
                type: 'newline',
                value: '10'
            },
            {
                type: 'text',
                value: `Date :  ${dayjs(job.timestamp).format('DD/MM/YYYY HH:mm:ss')}`,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 9,
                    fontStyle: 'normal',
                    align: 'left'
                }
            },
            {
                type: 'text',
                value: `Employee :  ${job.employee.name}`,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 9,
                    fontStyle: 'normal',
                    align: 'left'
                }
            },
            {
                type: 'text',
                value: `${job.table} (ยกเลิกสินค้า)`,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 14,
                    fontStyle: 'bold',
                    align: 'center'
                }
            },
             
          
            {
                type: 'newline',
                value: "10"
            },
            {
                type: 'line'
            }
        )
        console.log("job=>", job)
        //draw items
        job.items.forEach(item => {
            content.push({
                type: 'text2cols',
                value: `${item.qty}   ${item.name}`,
               // value2: `${item.price}`,
                  value2: ` `,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 14,
                    fontStyle: 'strikeOut',
                }
            },)

            if (item.options2 && item.options2.length > 0) {
                item.options2.forEach(option => {
                    content.push({
                        type: 'text2cols',
                        value: `     - ${option.name}  x${option.qty}`,
                        value2: ` `,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: 15,
                            fontStyle: 'strikeOut',
                        }
                    })
                })
            }

            if (item.comments) {
                content.push({
                    type: 'text2cols',
                    value: `     # ${item.comments}`,
                    value2: ` `,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 15,
                        fontStyle: 'normal',
                    }
                })
            }

        })
        // draw line
        content.push({ type: 'line' })

        content.push(
            {
                type: 'newline',
                value: '14'
            },
            {
                type: 'text',
                value: `Reason :  ${job.note}`,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fontStyle: 'normal',
                    align: 'left'
                }
            },
            {
                type: 'newline',
                value: '14'
            },
            {
                type: 'text',
                value: `Sign : _________________ `,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fontStyle: 'normal',
                    align: 'left'
                }
            },
            {
                type: 'newline',
                value: '14'
            },
            {
                type: 'text',
                value: `*** Original Copy to Cashier ***`,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fontStyle: 'normal',
                    align: 'center'
                }
            },
        )

        console.log("summary void item=>", content)
        let cmd = {
            printerPreset: "0",
            timestamp: new Date().toISOString(),
            date: dayjs().format('YYYY-MM-DD'),
            jobId: new Date().valueOf().toString(),
            content
        }
        this.print_queues.push(cmd)
    }

    buildPrintByProductGroupOutput(job, printer, items, productGroupsHolder, index) {
        const settingsStore = useSettingsStore();
        const isShowItemPrice = settingsStore.getValueBysettingId(SETTING_ID.PRINTER_JOB_SHOW_PRICE, false)  
        let groups = this.reduceItemGroup(items, productGroupsHolder)
        let commentBill = job?.commentBill || ""
      
        groups.forEach((group, i)=>{
            let content = []
            let _logsText   = ""
            // draw detail store
            content.push(
                {
                    type: 'text',
                    value: `printer${printer.replace("p","")} `,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 10,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'newline',
                    value: '10'
                },
                {
                    type: 'text',
                    value: `Date :  ${dayjs(job.timestamp).format('DD/MM/YYYY HH:mm:ss')}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'text',
                    value: `Employee :  ${job.employee.name}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                // {
                //     type: 'text',
                //     value: `No Customer :  ${job.guests}`,
                //     style: {
                //         fontFamily: 'Arial',
                //         fontSize: 9,
                //         fontStyle: 'normal',
                //         align: 'left'
                //     }
                // },            
                {
                    type: 'newline',
                    value: "10"
                },
                {
                    type: 'text',
                    value: `Table ${job.table}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 16,
                        fontStyle: 'bold',
                        align: 'center'
                    }
                },
                {
                    type: 'text',
                    value: `Group ${group.group.name}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 16,
                        fontStyle: 'bold',
                        align: 'center'
                    }
                },
                {
                    type: 'newline',
                    value: "10"
                },
                {
                    type: 'line'
                }
            ) 

            //draw items
            group.items.forEach(item => {
                content.push({
                    type: 'text2cols',
                    value: `${item.qty}   ${item.name}`,
                    value2: `${isShowItemPrice ? item.price : ' '}`,
                    // value2: ` `,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: this.customFontSize(16),
                        fontStyle: 'normal',
                    }
                },)
    
                _logsText += `${item.name} x${item.qty}, `
    
                if (item.options2 && item.options2.length > 0) {
                    item.options2.forEach(option => {
                        content.push({
                            type: 'text2cols',
                            value: `     - ${option.name}  x${option.qty}`,
                            value2: ` `,
                            style: {
                                fontFamily: 'Arial',
                                fontSize: this.customFontSize(15),
                                fontStyle: 'normal',
                            }
                        })
                    })
                }
    
                if (item.comments) {
                    content.push({
                        type: 'text2cols',
                        value: `     # ${item.comments}`,
                        value2: ` `,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: this.customFontSize(15),
                            fontStyle: 'normal',
                        }
                    })
                }
    
                content.push( {
                    type: 'newline',
                    value: "10"
                })
            })
            // draw line
            content.push({ type: 'line' },                     {
                type: 'text',                    
                value: `Note : ***${commentBill}`,              
                style: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fontStyle: 'bold',                            
                }
            },)
    
            let cmd = {
                // printerName: PRINTER_NAME,
                printerPreset: index.toString(),
                timestamp: dayjs(job.timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                date: dayjs().format('YYYY-MM-DD'),
                jobId: new Date().valueOf().toString() + i + '_' + printer,
                content,
                logs: true, 
                table : job.table,
                logsText : _logsText
            }
            this.print_queues.push(cmd)
        })



    }

    buildOneByOneOutput(job, printer, items, index) {
        const settingsStore = useSettingsStore();
        const isShowItemPrice = settingsStore.getValueBysettingId(SETTING_ID.PRINTER_JOB_SHOW_PRICE, false)
        let commentBill = job?.commentBill || ""
        items.forEach((item, i) => {

            // one by one
            let content = []
            let _logsText = `${item.name} x${item.qty}, `
            
            content.push(                 
                {
                    type: 'text',
                    value: `printer${printer.replace("p","")}  :  ${dayjs(job.timestamp).format('DD/MM/YYYY HH:mm:ss')}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'text',
                    value: `Employee :  ${job.employee.name}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',
                        align: 'left'
                    }
                },
                {
                    type: 'newline',
                    value: "10"
                },
                {
                    type: 'text',
                    value: `${job.table}`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 14,
                        fontStyle: 'bold',
                        align: 'center'
                    }
                },
                {
                    type: 'newline',
                    value: "10"
                },
                {
                    type: 'line'
                },
                {
                    type: 'newline',
                    value: '10'
                },
                {
                    type: 'text2cols',
                    value: `${item.qty}  ${item.name}`,
                    value2: `${isShowItemPrice ? item.price : ''}`,
                    //  value2: ` `,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: this.customFontSize(16),
                        fontStyle: 'normal',
                    }
                },
             
            )

            if (item.options2 && item.options2.length > 0) {
                item.options2.forEach(option => {
                    content.push({
                        type: 'text2cols',
                        value: `     - ${option.name}  x${option.qty}`,
                        value2: ` `,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: this.customFontSize(15),
                            fontStyle: 'normal',
                        }
                    })
                })
            }

            if (item.comments) {
                content.push({
                    type: 'text2cols',
                    value: `     # ${item.comments}`,
                    value2: ` `,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: this.customFontSize(15),
                        fontStyle: 'normal',
                    }
                })
            }

             content.push(
 
                {
                    type: 'newline',
                    value: '10'
                },
                {
                    type: 'line'
                },
                {
                    type: 'text',                    
                    value: `Note : ***${commentBill}`,              
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 10,
                        fontStyle: 'bold',                        
                    }
                },
                {
                    type: 'text',                  
     
                    value: `(${i+1}/${items.length})`,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: 9,
                        fontStyle: 'normal',    
                        align:"right"                    
                    }
                },
                {
                    type: 'newline',
                    value: '10'
                },
                
            )
            let cmd = {
                printerPreset: index.toString(),
                timestamp: dayjs(job.timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                date: dayjs().format('YYYY-MM-DD'),
                jobId: new Date().valueOf().toString() + '_' + printer + '_' + item.productId + '_' + i,
                content,
                logs : true,
                logsText : _logsText,
                table : job.table
            }
            this.print_queues.push(cmd)
        })
    }

    buildOneQtyEachOutput(job, printer, items, index) {
        const settingsStore = useSettingsStore();
        const isShowItemPrice = settingsStore.getValueBysettingId(SETTING_ID.PRINTER_JOB_SHOW_PRICE, false)
        let commentBill = job?.commentBill || ""
        items.forEach((item, i) => {

            for(let index = 0; index < item.qty; index++) {
                // one by one
                let content = []
                let _logsText = `${item.name} x1,`
                
                content.push(

                    
                    {
                        type: 'text',
                        value: `printer${printer.replace("p","")}  :  ${dayjs(job.timestamp).format('DD/MM/YYYY HH:mm:ss')}`,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: 9,
                            fontStyle: 'normal',
                            align: 'left'
                        }
                    },
                    {
                        type: 'text',
                        value: `Employee :  ${job.employee.name}`,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: 9,
                            fontStyle: 'normal',
                            align: 'left'
                        }
                    },
                    {
                        type: 'newline',
                        value: "10"
                    },
                    {
                        type: 'text',
                        value: `${job.table}`,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: 14,
                            fontStyle: 'bold',
                            align: 'center'
                        }
                    },
                    {
                        type: 'newline',
                        value: "10"
                    },
                    {
                        type: 'line'
                    },
                    {
                        type: 'newline',
                        value: '10'
                    },
                    {
                        type: 'text2cols',
                        value: `1  ${item.name}`,
                        value2: `${isShowItemPrice ? item.price : ''}`,
                        //  value2: ` `,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: this.customFontSize(16),
                            fontStyle: 'normal',
                        }
                    },
                
                )

                if (item.options2 && item.options2.length > 0) {
                    item.options2.forEach(option => {
                        content.push({
                            type: 'text2cols',
                            value: `     - ${option.name}  x${option.qty}`,
                            value2: ` `,
                            style: {
                                fontFamily: 'Arial',
                                fontSize: this.customFontSize(15),
                                fontStyle: 'normal',
                            }
                        })
                    })
                }

                if (item.comments) {
                    content.push({
                        type: 'text2cols',
                        value: `     # ${item.comments}`,
                        value2: ` `,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: this.customFontSize(15),
                            fontStyle: 'normal',
                        }
                    })
                }

                content.push(
    
                    {
                        type: 'newline',
                        value: '10'
                    },
                    {
                        type: 'line'
                    },
                    {
                        type: 'text',                    
                        value: `Note : ***${commentBill}`,
                        value2: `(${index+1}/${item.qty})`,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: 10,
                            fontStyle: 'bold',                            
                        }
                    },
        
                    {
                        type: 'text',                                            
                        value: `(${index+1}/${item.qty})`,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: 9,
                            fontStyle: 'normal',    
                            align:"right"                        
                        }
                    },

                    {
                        type: 'newline',
                        value: '10'
                    },
                    
                )

                let cmd = {
                    printerPreset: index.toString(),
                    timestamp: dayjs(job.timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                    date: dayjs().format('YYYY-MM-DD'),
                    jobId: `${new Date().valueOf().toString()}_${printer}_${item.productId}_${i}_${index}`,
                    content,
                    logs : true,
                    logsText : _logsText,
                    table : job.table
                }


                this.print_queues.push(cmd)
            }


        })
    }

    buildSummaryOuput(job, printer, items, index) {
        const settingsStore = useSettingsStore();
        const isShowItemPrice = settingsStore.getValueBysettingId(SETTING_ID.PRINTER_JOB_SHOW_PRICE, false)
        let content = []
        let _logsText   = ""
        let commentBill = job?.commentBill || ""

        // draw detail store
        content.push(
            
            {
                type: 'text',
                value: `printer${printer.replace("p","")}  :  ${dayjs(job.timestamp).format('DD/MM/YYYY HH:mm:ss')}`,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 9,
                    fontStyle: 'normal',
                    align: 'left'
                }
            },
            {
                type: 'text',
                value: `Employee :  ${job.employee.name}`,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 9,
                    fontStyle: 'normal',
                    align: 'left'
                }
            },
            // {
            //     type: 'text',
            //     value: `No Customer :  ${job.guests}`,
            //     style: {
            //         fontFamily: 'Arial',
            //         fontSize: 9,
            //         fontStyle: 'normal',
            //         align: 'left'
            //     }
            // },            
            {
                type: 'newline',
                value: "5"
            },
            {
                flag:"commentBill",
                type: 'text',
                value: `Note : ***${commentBill}`,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 12,
                    fontStyle: 'bold',
                    align: 'left'
                }
            },   
            {
                type: 'newline',
                value: "10"
            },         
            {
                type: 'text',
                value: `${job.table}`,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 16,
                    fontStyle: 'bold',
                    align: 'center'
                }
            },
            {
                type: 'newline',
                value: "10"
            },
            {
                type: 'line'
            }
        )
        //draw items
        items.forEach(item => {
            content.push({
                type: 'text2cols',
                value: `${item.qty}   ${item.name}`,
                value2: `${isShowItemPrice ? item.price : ' '}`,
                // value2: ` `,
                style: {
                    fontFamily: 'Arial',
                    fontSize: this.customFontSize(16),
                    fontStyle: 'normal',
                }
            },)

            _logsText += `${item.name} x${item.qty}, `

            if (item.options2 && item.options2.length > 0) {
                item.options2.forEach(option => {
                    content.push({
                        type: 'text2cols',
                        value: `     - ${option.name}  x${option.qty}`,
                        value2: ` `,
                        style: {
                            fontFamily: 'Arial',
                            fontSize: this.customFontSize(15),
                            fontStyle: 'normal',
                        }
                    })
                })
            }

            if (item.comments) {
                content.push({
                    type: 'text2cols',
                    value: `     # ${item.comments}`,
                    value2: ` `,
                    style: {
                        fontFamily: 'Arial',
                        fontSize: this.customFontSize(15),
                        fontStyle: 'normal',
                    }
                })
            }

            content.push( {
                type: 'newline',
                value: "10"
            })
        })
        // draw line
        content.push({ type: 'line' })

        content = this.filterFlagOut(content, "commentBill", commentBill)


        let cmd = {
            // printerName: PRINTER_NAME,
            printerPreset: index.toString(),
            timestamp: dayjs(job.timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            date: dayjs().format('YYYY-MM-DD'),
            jobId: new Date().valueOf().toString() + '_' + printer,
            content,
            logs: true, 
            table : job.table,
            logsText : _logsText
        }
        this.print_queues.push(cmd)
    }

    reduceItemGroup(items, productGroupsHolder){
        let groups= items.reduce((acc,value) => {
            let group = productGroupsHolder[value.productId]   
            if(acc[group.productId]==undefined){
                acc[group.productId] = {group:{}, items:[]}
            }
            acc[group.productId]['group'] = group
            acc[group.productId]['items'].push(value)
            return acc
        },{})  

        return Object.values(groups);
    }

    customFontSize(current:number){
        const settingsStore = useSettingsStore();
        const customFontSize = settingsStore.getValueBysettingId(SETTING_ID.PRINTER_JOB_KITCHEN_FONT_SIZE, 0)
        return current + Math.ceil(current*customFontSize)        
    }

    getTableState(table:String){
        const posStore = usePosStore();
        return posStore.tableState[table]
    }

    filterFlagOut(content:any, flagName:string, flagValue:any){
        let cloneContent = [...content]
        if(!flagValue){
            cloneContent = cloneContent.filter(c=> {
                if(c.flag ==undefined){
                    return c
                }

                if(c.flag !==flagName){
                    return c
                }
                
            })
        }

        return cloneContent
    }
}
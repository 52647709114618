import { Receipt } from "~~/interface/Receipt";
import dayjs from "dayjs";

// create class receipt builder
export default class PreviewBuilder {

    private receipt: any = null
    // private receipt: Receipt = null

    // create constructor
    constructor() {


        // this data will be change when build method is called
        this.receipt = {
            table: "A1",
            receiptId: "",
            storeId: "",
            createdAt: new Date().toISOString(),
            items: [],
            employee: {
                name: "",
                employeeId: ""
            },
            payment: {

                grandTotal: 0,
                list: [],

                received: 0,
                change: 0,

                subTotal: 0,
                dc1: [0, 0, 0],
                sc: [0, 0, 0],
                dc2: [0, 0, 0],
                cc: [0, 0, 0],
                tax: 0,
                rounding : 0,
                beforeTax : 0,
                note: ""
            },
            businessDate: new Date().toISOString(),
            metadata: {
                startDate: new Date().toISOString(),
                endDate: new Date().toISOString(),
                guests: 2,
                type: "dine_in",
                priceMode: "default",
                shift: "1",
            },




        }


    }


    // create method to build receipt
    build() {
        return this.receipt;
    }

    withTable(payload) {
        this.receipt.table = payload;
        return this;
    }

    withStoreId(payload) {
        this.receipt.storeId = payload;
        return this;
    }

    withItems(payload) {

        let items = payload.map((item) => {

            return {
                ...item,
                productId: item.productId,
                name: item.name,
                price: item.price,
                qty: item.qty,
            //    subTotal: item.subTotal,
                dc1: item.dc1,
                sc: item.sc,
                dc2: item.dc2,
                cc: item.cc,
                tax: item.tax,
                amount: item.amount,
            }
        })
        this.receipt.items = items;
        return this;
    }
    withPayment({paymentList, received, change, summary, note }) {
 
        
        // compute money
        this.receipt.payment = { 
            ...this.receipt.payment,
            ...summary
        }
        this.receipt.payment.list = paymentList;
        this.receipt.payment.received = 0;
        this.receipt.payment.change = 0;
        this.receipt.payment.note = note;
        return this;
    }
    withGuests(payload = 2) {
        this.receipt.metadata.guests = payload;
        return this;
    }
    withReceiptId(payload) {
       
        return this;
    }
    withEmployee(payload) {
        this.receipt.employee = { 
            employeeId: payload.employeeId,
            name: payload.name
        };
        return this;
    }
    withCreatedAt(payload) {
        this.receipt.createdAt = payload;
        return this;
    }
    withBusinessDate(payload) {
        this.receipt.businessDate = payload;
        return this;
    }
    withMetadata(payload) {
        this.receipt.metadata = payload;
        return this;
    }
    withNote(payload) {
        this.receipt.payment.note = payload;
        return this;
    }

    

}
import {   onValue } from 'firebase/database';
import { PiniaPluginContext } from 'pinia'



let count = 0

export default defineNuxtPlugin(({ $pinia }) => {


    setTimeout(() => {
        count++
        console.log('Plugin count:', count)
        let forceReloadHash = null;
        const webhashRef = useFirebase().webAppReloadRef()

        //firebase onChildAdded 
        onValue(webhashRef, (snapshot) => {
            //  
            if (forceReloadHash !== null && forceReloadHash !== snapshot.val()) {
                location.reload();
                console.log('forceReloadHash:', count)
            } else {
                forceReloadHash = snapshot.val();
            }
        });
    }, 2000)

})
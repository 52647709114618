import { child, set, onValue, update, remove, getDatabase , ref as refDb,} from 'firebase/database';
import { PiniaPluginContext } from 'pinia'
import { usePosStore } from '../stores/pos';
import { useEmployeePinStore } from '~/modules/setting/stores/employeePin';
import { useServerTime } from '~/modules/core/utils/timestamp';
import dayjs from 'dayjs';

function MyPiniaPlugin({ store }: PiniaPluginContext) {
           
          if (store.$id == 'pos') {  
            const DELAY = 3 // seconds
            
            setTimeout(() => { 
              clearData()
              runRequestPrint();
              }, 1000 * DELAY)
          }
    
     
}

function runRequestPrint(){
        const posStore = usePosStore()
        let fbInstance = useFirebase();
        let tablesStateRef = fbInstance.printPreviewRequestRef();
       

        onValue(tablesStateRef, (snapshot) => {
          if (snapshot.exists()) {
            const value = snapshot.val();  
           
            let updateData = {}
            Object.entries(value).forEach(([tableName, data]) => {  
              if(data){                   
                let serverTime = useServerTime().getCorrectedDayjsInstance()               
                let requestTime = dayjs(data?.timestamp);
                const diff = serverTime.diff(requestTime, 'seconds');  
               

                if (Math.abs(diff) < 10) {  
                  console.info("REQUEST PRINT PREVIEW......=======")
                  posStore.printPreviewFromMobile(tableName, data?.employee)
                }

                updateData[tableName] = null               
              }     
            });

            update(tablesStateRef, updateData);     
          } 
        }, (error) => {
          console.error("Error listening to tablePrint value:", error);
        });
}

function clearData(){
  let fbInstance = useFirebase();
  let tablesStateRef = fbInstance.printPreviewRequestRef();
  remove(tablesStateRef).then(() => console.log("clear job print preview..."))
}

export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(MyPiniaPlugin)
})


//create CRUD class with axios
import axios, { AxiosResponse } from 'axios';
import DataService from './dataservice.base';
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";

export default class JobsDataService extends DataService {


    // private db : any =  getFirestore() 
    constructor(path :string) {
        super(path);
        
    }
 

     // GET a single record by ID
    async getByDate(startDate : string): Promise<AxiosResponse> {         

        const db : any =  getFirestore() 
        let collectionRef= collection(db , "stores", this.storeId, "jobs")
        const q = query(collectionRef, where("businessDate", "==", startDate));

        const querySnapshot = await getDocs(q);

        let res = []
        querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        res.push(doc.data())
        });       

        return res;
        
        
    }            

    async getByDateRange(startDate : string, endDate:string, options:object): Promise<AxiosResponse> {
        if(options.documentId == undefined || options.key == undefined){
            console.log("Document or key not found")
            return Promise.reject(new Error ("Document or key not found "))
        }

        const {documentId, key} = options

        const _startDate = `${startDate}T00:00:00.000Z`
        const _endDate = `${endDate || startDate}T23:59:59.000Z`
      

        const db : any =  getFirestore() 
        let collectionRef= collection(db , "stores", this.storeId, documentId)
        const q = query(collectionRef, where(key, ">=", _startDate) , where(key, "<", this.getNextDay(_endDate)));


        const querySnapshot = await getDocs(q);

        let res = []
        querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        res.push(doc.data())
        });

        return res;       
        
    }

     // GET a single record by ID
     async getByRange(startDate : string, endDate : string ): Promise<AxiosResponse> {
        return await this.axios.get(`${this.apiUrl}${this.path}/${startDate}/${endDate}}?storeId=${this.storeId}`);
    }

    getNextDay(datePrefix: string): string {
        const date = new Date(datePrefix);
        date.setDate(date.getDate() + 1);
        return date.toISOString().substring(0, 10) + "T00:00:00"; // Format: YYYY-MM-DDTHH:MM:SS
    }
}
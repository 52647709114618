import _ from "lodash";
import { ItemOption } from "~~/interface/ItemSetting";

export default class TableBuilder {



    combinedItems() {

    }

    computeTableItems(tables, tableInfo, settings, tableState, channelSettings) {


        _.forEach(tables, (table, tableKey) => {
            const currentChannel = tableState[tableKey]?.channel
            const findedChannel = channelSettings.find(f=>f?.channelId == currentChannel?.channelId)
            const tableServiceCharge =  findedChannel?.serviceCharge === null ? null :findedChannel?.serviceCharge
            console.log("table sc=>", tableServiceCharge)

            let tableService = tableInfo[tableKey]?.sc || settings?.sc || [0, 10];
            if(tableServiceCharge!==null && tableServiceCharge!==undefined){
                tableService = [0,tableServiceCharge]
            }

          
            let tableDiscount = tableInfo[tableKey]?.dc2 || settings?.dc2 || [0, 0];
            let tableCard = tableInfo[tableKey]?.cc || settings?.cc || [0, 0];
            let tableTax = tableInfo[tableKey]?.tax || settings?.tax || [0, 0];
            //   let tableTax = 7;

            // dc2 =1
            let totalCost = 0;
            if (tableDiscount && tableDiscount[0] === 1) {
                table.items.forEach((item) => {
                    let addOn = 0;
                    let total = 0;

                    //topping
                    if (item.options2 as Array<ItemOption>) {
                        item.options2.forEach((option) => {
                            addOn += option.price * option.qty;
                        });
                    }
                    item.addOnPrice = addOn;
                    let extendedPrice = item.qty * (item.price + addOn);
                    let discountItem = 0;
                    //dicount by percentage
                    if (item.discountItem && item.discountItem[0] == 0) {
                        discountItem = - (extendedPrice * item.discountItem[1] / 100);
                    }

                    //discount by amount
                    if (item.discountItem && item.discountItem[0] == 1) {
                        discountItem = - item.discountItem[1];
                    }
                    total = extendedPrice + discountItem;
                    let extendedPriceWithDiscount = total;


                    //service charge
                    let service = 0;
                    if (tableService && tableService[1]) {
                        service = (1 * total) * tableService[1] / 100;
                        total = total + service;
                    }
                    totalCost += total;
                });
            }


            table.items.forEach((item) => {

                let addOn = 0;
                let total = 0;

                //topping
                if (item.options2 as Array<ItemOption>) {
                    item.options2.forEach((option) => {
                        addOn += option.price * option.qty;
                    });
                }
                item.addOnPrice = addOn;
                let extendedPrice = item.qty * (item.price + addOn);
                let discountItem = 0;
                //dicount by percentage
                if (item.discountItem && item.discountItem[0] == 0) {
                    discountItem = - (extendedPrice * item.discountItem[1] / 100);
                }

                //discount by amount
                if (item.discountItem && item.discountItem[0] == 1) {
                    discountItem = - item.discountItem[1];
                }
                total = extendedPrice + discountItem;
                let extendedPriceWithDiscount = total;


                //service charge
                let service = 0;
                if (tableService && tableService[1]) {
                    service = (1 * total) * tableService[1] / 100;
                    total = total + service;
                }


                let discountBill = 0;
                //dicount bill by percentage
                if (tableDiscount && tableDiscount[0] === 0) {
                    discountBill = - (total * tableDiscount[1] / 100);
                }

                //discount by amount
                if (tableDiscount && tableDiscount[0] === 1) {
                    const discountAmount = tableDiscount[1]

                    discountBill = - (total / totalCost * discountAmount);
                }
                total = total + discountBill;

                //card
                let card = 0;
                if (tableCard && tableCard[1]) {
                    card = total * tableCard[1] / 100;
                }
                total = total + card;

                //tax
                let tax = 0;
                //if tax  excluded 
                if (tableTax[0] === 2) {
                    tax = total * tableTax[1] / 100;
                    total = total + tax;
                }

                // if tax included
                if (tableTax[0] === 1) {
                    tax = total * tableTax[1] / (100 + tableTax[1]);
                    tax = -1* tax;
                }


                item.subTotal = extendedPriceWithDiscount;

                item.dc1 = [item.discountItem[0], item.discountItem[1], discountItem];
                item.sc = [tableService[0], tableService[1], service];
                item.dc2 = [tableDiscount[0], tableDiscount[1], discountBill];
                item.cc = [tableCard[0], tableCard[1], card];
                item.tax = [tableTax[0], tableTax[1], tax];

                item.extendedPriceWithDiscount = extendedPriceWithDiscount;
                item.amount = total;
                item.extendedPrice = extendedPrice;
            });
        });
    }


}


//create CRUD class with axios
import axios, { AxiosResponse } from 'axios';
import DataService from './dataservice.base';


export default class SettingsDataService extends DataService {

    
    constructor(path :string) {
        super(path);
        
    }


 

 
    async update(data:object){
        
        if( !data.storeId) { 
            data.storeId = this.storeId;
            data.updatedAt = new Date().toISOString();
        }

        //**** Please check url */
        const url = `${this.apiUrl}${this.path}/update`
        return await this.axios.post(url, data);
    }
}
export const sortTables = (arr: string[]) => {
  const collator = new Intl.Collator(undefined, { numeric: true });
  return arr.sort((a, b) => {
    const pattern = /([A-Za-z]+)(\d+)/;
    const [, zoneA, tableNumberA] = a.match(pattern) || ["", "", "0"];
    const [, zoneB, tableNumberB] = b.match(pattern) || ["", "", "0"];

    const tableNumberANum = parseInt(tableNumberA);
    const tableNumberBNum = parseInt(tableNumberB);

    if (zoneA < zoneB) {
      return -1;
    } else if (zoneA > zoneB) {
      return 1;
    } else {
      if (tableNumberANum < tableNumberBNum) {
        return -1;
      } else if (tableNumberANum > tableNumberBNum) {
        return 1;
      } else {
        return collator.compare(a, b);
      }
    }
  });
};

// [V1] sortTables()

// export const sortTables = function (arr) {
//   return arr.sort((a, b) => {
//     // Extract the zone and table number from the elements
//     const zoneA = a.match(/[A-Za-z]+/g)[0];
//     const zoneB = b.match(/[A-Za-z]+/g)[0];
//     const tableNumberA = parseInt(a.match(/\d+/g)[0]);
//     const tableNumberB = parseInt(b.match(/\d+/g)[0]);

//     // Compare the zones
//     if (zoneA < zoneB) {
//       return -1;
//     } else if (zoneA > zoneB) {
//       return 1;
//     }

//     // Zones are equal, compare the table numbers
//     return tableNumberA - tableNumberB;
//   });
// };
